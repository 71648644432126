<template>
  <div>
    <div class="row mx-0 pt-3">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue">Maximum</span>
        </div>
        <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div>
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="job-function"
          class="elevation-1 col-12 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.maximum_salary }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeStatus(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <!-- <td style="display: flex; flex-direction: row">
                <v-btn
                  class="
                    btn btn-sm btn-success
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class="
                    btn btn-sm btn-danger
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  fab
                  small
                  @click="deleteButtonClick(props.item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </td> -->
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="deleteButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>

          <!-- <template v-slot:top>
            <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              fab
              small
              @click="createFormEnable"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
      </div>
      <div class="col-md-5 pl-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm"
            class="card-label font-weight-bolder Main_Blue"
            >Add new Maximum Salary
          </span>
          <span
            v-if="updateForm"
            class="card-label font-weight-bolder Main_Blue"
            >Update Maximum Salary
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="maximumSalary"
              :rules="nameRules"
              label="Maximum salary"
              required
            ></v-text-field>
            <button
              type="button"
              @click="createMaximumSalary"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Save</span
              >
            </button>
            <!-- <v-btn
              style="padding: 8px !important"
              class="custom-add-new-record-button btn_red__ch"
              @click="cancel"
            >
              Cancel
            </v-btn> -->
          </v-form>
          <!-- create form end -->

          <!-- update form start -->
          <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="maximumSalary"
              :rules="nameRules"
              label="Maximum salary"
              required
            ></v-text-field>
            <button
              type="button"
              @click="updateMaximumSalary"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Update</span
              >
            </button>
            <v-btn
              style="padding: 8px !important"
              class="custom-add-new-record-button btn_red__ch"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-form>
        </div>
        <!-- update form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: true,
      updateForm: false,
      maximumSalary: "",
      maximum_salary_id: null,
      minimumSalary: "",
      minimum_salary_id: null,
      nameRules: [
        (v) => !!v || "Maximum salary is required",
        (v) =>
          (v && v.length <= 20) ||
          "Maximum salary must be less than 20 characters",
      ],
      headers: [
        { text: "Maximum Salary", value: "maximum_salary" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.fetch();
    this.fetch1();
  },
  methods: {
    cancel() {
      this.createForm = true;
      this.updateForm = false;
      this.maximumSalary = "";
      this.minimumSalary = "";
    },
    createFormEnable() {
      this.maximumSalary = "";
      this.minimumSalary = "";
      this.createForm = true;
      this.updateForm = false;
    },
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/maximum-salary`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetch1() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/minimum-salary`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createMaximumSalary() {
      if (this.maximumSalary == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      } else {
        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .post(`${API_URL}/maximum-salary`, {
            maximum_salary: this.maximumSalary,
          })
          .then((res) => {
            this.loading = false;
            this.success = true;
            this.maximumSalary = "";
            Swal.fire({
              title: "",
              text: "Maximum salary added",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            this.fetch();
          })
          .catch(() => {
            this.loading = false;
            Swal.fire({
              title: "",
              text: "Something went wrong!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          });
      }
    },
    updateMaximumSalary() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/maximum-salary/${this.maximum_salary_id}`, {
          maximum_salary: this.maximumSalary,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.maximumSalary = "";
          this.maximum_salary_id = "";
          Swal.fire({
            title: "",
            text: "Maximum salary updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    createMinimumSalary() {
      if (this.minimumSalary == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/minimum-salary`, {
          minimum_salary: this.minimumSalary,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.minimumSalary = "";
          Swal.fire({
            title: "",
            text: "Minimum salary added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch1();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateMinimumSalary() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/minimum-salary/${this.minimum_salary_id}`, {
          minimum_salary: this.minimumSalary,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.minimumSalary = "";
          this.minimum_salary_id = "";
          Swal.fire({
            title: "",
            text: "Minimum salary updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch1();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/maximum-salary/${item.id}`, {
          maximum_salary: item.maximum_salary,
          status: !item.status,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.maximumSalary = "";
          this.maximum_salary_id = "";
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeStatus1(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/minimum-salary/${item.id}`, {
          minimum_salary: item.minimum_salary,
          status: !item.status,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.minimumSalary = "";
          this.minimum_salary_id = "";
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.maximumSalary = item.maximum_salary;
      this.maximum_salary_id = item.id;
    },
    editButtonClick1(item) {
      this.createForm = false;
      this.updateForm = true;
      this.minimumSalary = item.minimum_salary;
      this.minimum_salary_id = item.id;
    },

    deleteButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/maximum-salary/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Maximum salary deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
    deleteButtonClick1(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/minimum-salary/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Minimum salary deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
  },
};
</script>
